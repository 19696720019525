<template>
  <div class="flightbooking_form_rightbox_top d-flex align-items-center" :class="{'fix': isScroll}">
    <div class="containercoutom">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">

          <fo-booking-navigation-header/>

        </div>
        <div class="logo">
          <img src="/assets/sabre-img/logo.png" alt="#" />
        </div>
      </div>
      <a href="#" class="btn-link" @click="() => gotoSearchPage()">
        <span>
          <i class="fa-solid fa-chevron-right"></i>
        </span>
        {{$t('sabre.booking.return-site')}}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from 'jquery';

export default {
  name: 'payment-form-point',
  components: {
    FoBookingNavigationHeader: () => import('@/sabre/common/atom/foBookingNavigationHeader'),
  },
  computed: {
    ...mapGetters({
      searchQuery: 'GET_SABRE_BOOKING_FLIGHT_SEARCH_QUERY',
    }),
  },
  data() {
    return {
      isScroll: false,
    };
  },
  mounted() {
    document.querySelector('.st-content').removeEventListener('scroll', this.onScroll);
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.isScroll = $('.st-content').scrollTop() > 100;
    },
    gotoSearchPage() {
      this.$store.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', []);
      this.$router.push({ path: `/sabre/fo-search-result/?${this.searchQuery}` });
    },
  },
};
</script>
